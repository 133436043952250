// nav menu

.nav-menu {
  margin-bottom: $spacer * 1.5;
  padding: 0;
  font-size: $h1-font-size;
  font-weight: $font-weight-light;
  line-height: 1;
  list-style: none;

  @include breakpoint(md) { font-size: $h1-font-size-md; }

  a {
    display: block;
    padding: .1em 0;
    color: $black;
    text-decoration: none;

    &.active {
      font-weight: $font-weight-bold;
    }
  }
}


// subnav menu

.subnav-menu {
  margin-bottom: $spacer * .5;
  padding: 0;
  font-size: $h3-font-size;
  font-weight: $font-weight-regular;
  list-style: none;

  a {
    padding-top: .25em;
    padding-bottom: .25em;
  }
}


// site nav

.site-nav {
  @include breakpoint(xl) {
    display: none;
  }
}

.site-nav-toggle {
  position: relative;
  display: inline-block;
  margin: -$spacer;
  padding: $spacer;
  background: none;
  border: none;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  opacity: 1;
  transition: opacity .2s $ease-default;

  @include breakpoint(md) {
    display: none;
  }

  &:hover {
    opacity: .7;
  }
}

.site-nav-toggle-icon {
  position: relative;
  display: block;
  width:  1.75 * $spacer;
  height: 1.75 * $spacer;
  color: $black;

  @include breakpoint(sm) {
    width:  2.25 * $spacer;
    height: 2.25 * $spacer;
  }

  span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: .0625 * $spacer;
    background-color: currentColor;
    transition: all .4s $ease-elastic;

    &:nth-child(1) { margin-top:  -.625 * $spacer; }
    &:nth-child(2) { margin-top: -.0625 * $spacer; }
    &:nth-child(3) { margin-top:     .5 * $spacer; }

    @include breakpoint(sm) {
      &:nth-child(1) { margin-top:   -.75 * $spacer; }
      &:nth-child(3) { margin-top:   .625 * $spacer; }
    }

    .site-nav-is-open & {
      &:nth-child(1) { transform: translateY( .5625 * $spacer) translateX(0) rotate( 45deg); }
      &:nth-child(2) { opacity: 0; }
      &:nth-child(3) { transform: translateY(-.5625 * $spacer) translateX(0) rotate(-45deg); }

      @include breakpoint(sm) {
        &:nth-child(1) { transform: translateY( .6875 * $spacer) translateX(0) rotate( 45deg); }
        &:nth-child(3) { transform: translateY(-.6875 * $spacer) translateX(0) rotate(-45deg); }
      }
    }
  }
}

.site-nav-menu {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: 100vh;
  padding-bottom: map-get($grid, 'sm') * 2 + 3.25rem;
  background-color: $white;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  transform: translateY(map-get($grid, 'sm') * -1);
  transition: opacity .2s $ease-default, visibility .2s $ease-default, transform .2s $ease-default;

  @include breakpoint(md) {
    position: static;
    height: auto;
    padding-bottom: 0;
    overflow-y: visible;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  > .container,
  > .container-full {
    @include breakpoint(md) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .nav-menu {
    padding-top: map-get($grid, 'sm');
    padding-bottom: map-get($grid, 'sm');

    @include breakpoint(md) {
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-size: $h2-font-size;

      li {
        display: inline-block;
        position: relative;
        margin-left: map-get($grid, 'md') * .5;
  
        &:hover {
          .subnav-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      }
    }
  }

  .subnav-menu {
    @include breakpoint(md) {
      position: absolute;
      top: 100%;
      right: 0;
      margin-right: map-get($grid, 'sm') * -1;
      padding: map-get($grid, 'sm') * .5 map-get($grid, 'sm') map-get($grid, 'sm');
      text-align: right;
      background-color: $white;
      opacity: 0;
      visibility: hidden;
      transform: translateY(map-get($grid, 'sm') * -.5);
      transition: opacity .2s $ease-default, visibility .2s $ease-default, transform .2s $ease-default;

      li {
        display: block;
        margin-left: 0;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        width: 100vw;
        background-color: $white;
      }

      &::after {
        right: auto;
        left: 100%;
      }
    }
  }

  .site-nav-is-open & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
