// breakpoints

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 960px,
  xl: 1200px
);


// colours

$black:                   #000;
$dark:                 #1a1a1a;
$mid:                  #9b9b9b;
$light:                #f2f2f2;
$white:                   #fff;

$body-colour:             $white;
$body-background-color:    $dark;


// spacing

$spacer:              1rem;

$grid: (
  sm: 1.25rem,
  md: 2.5rem,
  lg: 3.125rem
);
$grid-max-width: 89.375rem; // 1430px

$sidebar-width:      21rem; // 336px


// fonts

$font-weight-light:                     100;
$font-weight-regular:                   300;
$font-weight-bold:                      500;

$body-font-family:     'Roboto', sans-serif;
$body-font-size:                       1rem;
$body-font-weight:     $font-weight-regular;
$body-line-height:                      1.5;

$headings-font-weight: $font-weight-regular;
$headings-line-height:                 1.25;

$h1-font-size:                      2.25rem; // 36px
$h2-font-size:                       1.5rem; // 24px
$h3-font-size:                     1.125rem; // 18px
$h4-font-size:                         1rem; // 16px
$h5-font-size:                     .8125rem; // 13px
$h6-font-size:                       .75rem; // 12px

$h1-font-size-md:                      3rem; // 48px


// animations

$ease-default: cubic-bezier(.25, .01, .25, 1);
$ease-elastic: cubic-bezier(0.68, -0.55, 0.265, 1.55);
