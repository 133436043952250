.site-footer {
  display: flex;
  flex-direction: column;
  padding-top: map-get($grid, 'md');
  padding-bottom: map-get($grid, 'md');
  color: $black;
  background-color: $white;

  @include breakpoint(xl) {
    justify-content: flex-end;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: $sidebar-width;
    padding-top: map-get($grid, 'lg') * 2 + 3.25rem;

    > .container,
    > .container-full {
      max-height: 100%;
      overflow-y: auto;
    }
  }
}
