html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  color: $body-colour;
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  background-color: $body-background-color;
  overscroll-behavior: none;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, dl {
  margin: 0 0 $spacer;
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

h1 { font-size: $h1-font-size; font-weight: $font-weight-light; @include breakpoint(md) { font-size: $h1-font-size-md; } }
h2 { font-size: $h2-font-size; }
h3 { font-size: $h3-font-size; }
h4 { font-size: $h4-font-size; }
h5 { font-size: $h5-font-size; }
h6 { font-size: $h6-font-size; }

.h1 { @extend h1; }
.h2 { @extend h2; }
.h3 { @extend h3; }
.h4 { @extend h4; }
.h5 { @extend h5; }
.h6 { @extend h6; }

a {
  color: inherit;
  text-decoration: underline;
  opacity: 1;
  transition: opacity .2s $ease-default;

  &:hover {
    opacity: .6;
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

b,
strong {
  font-weight: $font-weight-bold;
}
