.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: map-get($grid, 'sm');
  padding-bottom: map-get($grid, 'sm');
  color: $black;
  background-color: $white;
  z-index: 100;

  @include breakpoint(xl) {
    width: $sidebar-width;
    padding-top: map-get($grid, 'lg');
    padding-bottom: map-get($grid, 'lg');
  }

  > .container,
  > .container-full {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(md) {
      align-items: flex-end;
    }
  }
}
