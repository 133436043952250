.meta-list {
  padding: 0;
  list-style: none;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.meta-list-item {
  margin-bottom: $spacer / 2;
  font-weight: $font-weight-bold;
}

.meta-list-heading {
  display: inline;
  margin: 0 .25em 0 0;
  opacity: .5;
}
