// hide

.hide { display: none !important; }

.hide-sm-up { @include breakpoint(sm) { display: none !important; } }
.hide-md-up { @include breakpoint(md) { display: none !important; } }
.hide-lg-up { @include breakpoint(lg) { display: none !important; } }
.hide-xl-up { @include breakpoint(xl) { display: none !important; } }

.hide-sm-down { @include breakpoint(sm-max) { display: none !important; } }
.hide-md-down { @include breakpoint(md-max) { display: none !important; } }
.hide-lg-down { @include breakpoint(lg-max) { display: none !important; } }
.hide-xl-down { @include breakpoint(xl-max) { display: none !important; } }


// text align

.text-align-right { text-align: right; }

.text-align-right-sm-up { @include breakpoint(sm) { text-align: right; } }
.text-align-right-md-up { @include breakpoint(md) { text-align: right; } }
.text-align-right-lg-up { @include breakpoint(lg) { text-align: right; } }
.text-align-right-xl-up { @include breakpoint(xl) { text-align: right; } }

.text-align-right-sm-down { @include breakpoint(sm-max) { text-align: right; } }
.text-align-right-md-down { @include breakpoint(md-max) { text-align: right; } }
.text-align-right-lg-down { @include breakpoint(lg-max) { text-align: right; } }
.text-align-right-xl-down { @include breakpoint(xl-max) { text-align: right; } }


// background colours

.bg-black {                background-color: $black; }
.bg-dark  {                background-color:  $dark; }
.bg-mid   { color: $black; background-color:   $mid; }
.bg-light { color: $black; background-color: $light; }
.bg-white { color: $black; background-color: $white; }


// font sizes

.has-small-font-size,
.has-medium-font-size,
.has-large-font-size,
.has-huge-font-size {
  line-height: $headings-line-height;
}

.has-small-font-size  { font-size: $h5-font-size; }
.has-medium-font-size { font-size: $h3-font-size; }
.has-large-font-size  { font-size: $h2-font-size; }
.has-huge-font-size   { font-size: $h1-font-size; font-weight: $font-weight-light; @include breakpoint(md) { font-size: $h1-font-size-md; } }


// wp-block-image

.wp-block-image {
  margin: 0 0 $spacer;
  padding: $spacer 0;
}
