.gallery {

}

.gallery-image {
  margin-bottom: map-get($grid, 'sm');

  @include breakpoint(md) {
    margin-bottom: map-get($grid, 'md');
  }

  @include breakpoint(lg) {
    margin-bottom: map-get($grid, 'lg');
  }
}

.gallery-two-columns {
  @include breakpoint(md) {
    column-count: 2;
    column-gap: map-get($grid, 'md');
  }

  @include breakpoint(lg) {
    column-gap: map-get($grid, 'lg');
  }
}
