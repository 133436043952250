.button {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0 1em 0 0;
  color: inherit;
  font-size: 1.125rem;
  font-weight: $font-weight-regular;
  line-height: 1.2;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  transition: opacity .2s $ease-default;

  &:hover {
    opacity: .6;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: .5em;
    height: .0625rem;
    background-color: currentColor;
    transform: translateY(.175em) rotate(-45deg);
  }

  &::after {
    transform: translateY(-.175em) rotate(45deg);
  }

  &.outlined {
    padding: .6em 2em .6em 1em;
    border: 1px solid currentColor;

    &::before,
    &::after {
      right: 1em;
    }
  }
}
