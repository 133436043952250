.slider {
  position: relative;

  @include breakpoint(landscape) {
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: map-get($grid, 'md');
      left: 50%;
      width: 2rem;
      height: .0625rem;
      margin-bottom: .75rem;
      background-color: $white;
      transform: translateX(-.3rem) rotate(-45deg);
      pointer-events: none;
      z-index: 1;
    }
  
    &::after {
      transform: translateX(-1.7rem) rotate(45deg);
    }
  }

  @include breakpoint(xl) {
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: map-get($grid, 'md');
      left: 50%;
      width: 2rem;
      height: .0625rem;
      margin-bottom: .75rem;
      background-color: $white;
      transform: translateX(-.3rem) rotate(-45deg);
      pointer-events: none;
      z-index: 1;
    }
  
    &::after {
      transform: translateX(-1.7rem) rotate(45deg);
    }
  }

  .flickity-page-dots {
    bottom: 0;
    left: 0;
    width: auto;
    max-width: 50%;
    padding-top:    map-get($grid, 'sm');
    padding-right:  map-get($grid, 'sm') * .5;
    padding-bottom: map-get($grid, 'sm');
    padding-left:   map-get($grid, 'sm');
    line-height: $body-line-height;
    text-align: left;

    @include breakpoint(md) {
      padding-right:  map-get($grid, 'md') * .5;
      padding-left:   map-get($grid, 'md');
    }

    @include breakpoint(lg) {
      padding-right:  map-get($grid, 'lg') * .5;
      padding-left:   map-get($grid, 'lg');
    }

    @include breakpoint(landscape) {
      right: calc(50% - 1.5rem);
      padding-right: 3rem;
      padding-bottom: map-get($grid, 'md');
      max-width: none;
    }

    @include breakpoint(xl) {
      right: calc(50% - 1.5rem);
      padding-right: 3rem;
      padding-bottom: map-get($grid, 'md');
      max-width: none;
    }

    .dot {
      margin: 0 $spacer 0 0;
      background-color: $black;

      @include breakpoint(landscape) {
        background-color: $white;
      }

      @include breakpoint(xl) {
        background-color: $white;
      }
    }
  }
}

.slide {
  display: block;
  position: relative;
  width: 100%;
  text-decoration: none;

  @include breakpoint(landscape) {
    height: calc(100vh - #{map-get($grid, 'sm') * 2 + 3.25rem});
  }

  @include breakpoint(xl) {
    height: 100vh;
  }

  &:hover {
    opacity: 1;

    .slide-image {
      transform: scale(1.05);
    }
  }
}

.slide-thumbnail {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 66.6666666667%;
  overflow: hidden;

  @include breakpoint(landscape) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    padding-bottom: 0;
  }

  @include breakpoint(xl) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    padding-bottom: 0;
  }
}

.slide-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(1);
  transition: transform 2s $ease-default;
}

.slide-caption {
  position: relative;
  padding-top:    map-get($grid, 'sm');
  padding-right:  map-get($grid, 'sm');
  padding-bottom: map-get($grid, 'sm');
  padding-left:   calc(50% + #{map-get($grid, 'sm') * .5});
  text-align: right;

  @include breakpoint(md) {
    padding-right:  map-get($grid, 'md');
    padding-left:   calc(50% + #{map-get($grid, 'md') * .5});
  }

  @include breakpoint(lg) {
    padding-right:  map-get($grid, 'lg');
    padding-left:   calc(50% + #{map-get($grid, 'lg') * .5});
  }

  @include breakpoint(landscape) {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: map-get($grid, 'md');
    padding-left: calc(50% + 1.5rem);
    color: $white;
    text-shadow: 0 0 $black;
  }

  @include breakpoint(xl) {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: map-get($grid, 'md');
    padding-left: calc(50% + 1.5rem);
    color: $white;
    text-shadow: 0 0 .5em $black;
  }
}

.slide-title {
  margin-bottom: 0;
}

.slide-description {
  margin-bottom: 0;
  font-size: .75rem;
}
