input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="search"],
textarea,
select {
  display: block;
  width: 100%;
  padding: .75em;
  color: $black;
  font-family: $body-font-family;
  font-size: 1rem;
  font-weight: $font-weight-regular;
  line-height: 1.2;
  background: $white;
  border: 1px solid transparent;
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
  }
}

textarea {
  min-height: $spacer * 10;
  resize: vertical;
}

::placeholder {
  color: inherit;
}


// generic forms

.form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 (map-get($grid, 'sm') * -.5);
  padding: 0;
  list-style: none;
}

.form-field {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: $spacer * 2;
  padding-right: map-get($grid, 'sm') * .5;
  padding-left:  map-get($grid, 'sm') * .5;

  label {
    display: block;
    margin-bottom: $spacer / 4;
  }
}

.form-field-half {
  flex-basis: 50%;
  max-width:  50%;
}


// folio filter by

.folio-filter-by {
  margin-top: ($spacer * -1.5);
  padding-bottom: ($spacer * .75);

  input[type="text"] {
    padding: .6em;
    color: $white;
    font-size: 1.125rem;
    background-color: transparent;
    border-color: rgba($white, .25);

    &::placeholder {
      opacity: .25;
      transition: opacity .2s $ease-default;
    }

    &:hover,
    &:active,
    &:focus {
      &::placeholder {
        opacity: 1;
      }
    }
  }
}
