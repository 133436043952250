// breakpoints

@mixin breakpoint($breakpoint) {
  @each $bp, $w in $breakpoints {
    @if str-index($breakpoint, max) and $bp == str-slice($breakpoint, 1, 2) {
      @media screen and (max-width: #{$w - 1}) { @content; }
    }
    @else if $bp == $breakpoint {
      @media screen and (min-width: $w) { @content; }
    }
  }
  @if $breakpoint == 'portrait' {
    @media screen and (orientation: portrait) { @content; }
  }
  @else if $breakpoint == 'landscape' {
    @media screen and (orientation: landscape) { @content; }
  }
}
