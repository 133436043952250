.folio-item-link {
  display: block;
  margin-bottom: $spacer * 2;
  text-decoration: none;

  &:hover {
    opacity: 1;

    .folio-item-image {
      img {
        transform: scale(1.05);
      }
    }
  }
}

.folio-item-thumbnail {
  position: relative;
  width: 100%;
  height: 0;
  margin-bottom: $spacer / 2;
  padding-bottom: 66.6666666667%;
  overflow: hidden;
  background-color: $black;

  &.has-image {
    background-image: url(../images/loading.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
  }
}

.folio-item-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(1);
    transition: opacity .5s $ease-default, transform 2s $ease-default;

    &.loaded {
      opacity: 1;
    }
  }
}
