.breadcrumb-heading {
  display: inline;
}

.breadcrumbs {
  display: inline-flex;
  flex-direction: row-reverse;
  font-size: 1.125rem;

  a {
    position: relative;
    padding-left: 2em;
    opacity: .25;
    text-decoration: none;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: .75em;
      width: .5em;
      height: .0625rem;
      background-color: currentColor;
      transform: translateY(.175em) rotate(45deg);
    }

    &::after {
      transform: translateY(-.175em) rotate(-45deg);
    }

    &:hover {
      opacity: 1;
    }
  }
}
